<template>
    <metainfo></metainfo>
    <section>
        <header class="w-screen h-screen/2">
            <div class="film w-full h-full flex">
                <div class="mx-auto my-auto">
                    <p class="font-medium text-lg md:text-2xl text-gray-300">OUR</p>
                    <h1 class="text-6xl md:text-8xl mx-auto my-auto uppercase text-gray-50 font-semibold">Services</h1>
                </div>
            </div>
        </header>
        <div class="md:grid md:grid-cols-3 md:gap-4 px-2 lg:pl-4 lg:pr-24 3xl:pl-72 3xl:pr-96 py-10 space-y-8 md:space-y-0">
            <div>
                <div class="w-full bg-white cursor-pointer hover:shadow-xl shadow-lg rounded-lg">
                    <div class="design h-56 rounded-t-lg">
                        <div class="film flex h-full rounded-t-lg">
                            <h1 class="uppercase text-3xl md:text-2xl font-bold text-gray-50 mx-auto my-auto">DESIGN</h1>
                        </div>
                    </div>
                    <div class="py-4 px-2 text-gray-700 space-y-3">
                        <div v-for="service in services.design" :key="service">
                            <p class="flex shadow py-2 px-2 rounded-lg">
                                <span class="my-auto pr-2 text-gray-300">
                                    <i class="fas fa-circle text-xs pb-1"></i>
                                </span>
                                <span class="my-auto lg:text-lg font-medium">{{service}}</span>
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div>
                <div class="w-full shadow-lg rounded-lg cursor-pointer hover:shadow-xl">
                    <div class="prod h-56 rounded-t-lg">
                        <div class="film flex h-full rounded-t-lg">
                            <h1 class="uppercase text-3xl md:text-2xl font-bold text-gray-50 mx-auto my-auto">PRODUCTION</h1>
                        </div>
                    </div>
                    <div class="py-4 px-2 text-gray-700 space-y-3">
                        <div v-for="service in services.production" :key="service">
                            <p class="flex shadow py-2 px-2 rounded-lg">
                                <span class="my-auto pr-2 text-gray-300">
                                    <i class="fas fa-circle text-xs pb-1"></i>
                                </span>
                                <span class="my-auto lg:text-lg font-medium">{{service}}</span>
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div>
                <div class="w-full shadow-lg rounded-lg cursor-pointer hover:shadow-xl">
                    <div class="edit h-56 rounded-t-lg">
                        <div class="film flex h-full rounded-t-lg">
                            <h1 class="uppercase text-3xl md:text-2xl font-bold text-gray-50 mx-auto my-auto text-center">EDITORIAL <br>& <br>POST PRODUCTION</h1>
                        </div>
                    </div>
                    <div class="py-4 px-2 text-gray-700 space-y-3">
                        <div v-for="service in services.editing" :key="service">
                            <p class="flex shadow py-2 px-2 rounded-lg">
                                <span class="my-auto pr-2 text-gray-300">
                                    <i class="fas fa-circle text-xs pb-1"></i>
                                </span>
                                <span class="my-auto lg:text-lg font-medium">{{service}}</span>
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
        <div class="flex pb-10">
            <div class="mx-auto px-4 pb-6 md:w-screen/2 xl:w-screen/3">
                <p class="text-gray-600">
                    We do not shoot weddings and events at the moment. For any other concepts that may not be listed above, please reach out to us. We're always open to answer your questions and discuss ideas.
                </p>
                <p class="text-lg font-medium text-gray-700 pt-4">
                    Contact us For Booking:
                </p>
                <p class="flex text-gray-700">
                    <span class="my-auto pr-1">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            class="h-5 w-5" 
                            fill="none" 
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                    </span>
                    <span class="my-auto">
                        <a href="tel:929-352-4485" class=""> (929) 352-4485</a>
                    </span>
                </p>
                <p class="flex text-gray-700">
                    <span class="my-auto pr-1">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            class="h-5 w-5" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    </span>
                    <span class="my-auto">
                        <a href="mailto:info@platinumtagsmedia.com">info@platinumtagsmedia.com</a>
                    </span>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Services',
    metaInfo() {
        return {
            title: 'PlatinumTags Media | Our Services',
        }
    },

    mounted(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },

    data(){
        return {
            services: {
                production: [
                    'Promotional Videos',
                    'Sales Videos',
                    'Music Videos',
                    'Documentaries',
                    'Short Films',
                    'Feature Films'
                ],
                editing: [
                   'Editing',
                   'Visual Effects',
                    'Color Grading/Correction'
                ],
                design: [
                    'Logos',
                    'Flyers',
                    'Posters',
                    'Business Cards',
                    'CD Covers',
                    'Book Covers',
                    'Illustration',
                    'Photoshop'
                ]
            }
        }
    }
}
</script>

<style scoped>
    header{
        background: url('../assets/img/camera-1.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .prod{
        background: url('../assets/img/film.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .edit{
        background: url('../assets/img/editor.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .design{
        background: url('../assets/img/design.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .film{
        background: rgb(0, 0, 0, 0.5);
    }

    header>.film{
        background: rgb(0, 0, 0, 0.8);
    }
</style>